import { productDetailPayloadToBaseCarData } from 'src/data/ProductApi/ProductDetailPayloadToBaseCarData';
import { BaseCarData } from 'src/types/Car.types';
import { ProductListDataRow } from 'src/types/CataloguePage.types';
import { ProductDetailsPayload } from 'src/types/ProductDetail.type';
import { pushToDataLayer } from './pushToDataLayer';
import { EventInfo } from './pushToDataLayerFinanceCalculator';

export interface DataLayerProduct {
  item_id: string;
  item_name: string;
  currency: string;
  index: number;
  item_brand: string | null;
  item_category: string | null;
  item_category2: string | null;
  item_category3: string | null;
  item_category4: string | null;
  item_category5: string | null;
  item_variant: string | null;
  price: number;
  quantity: number;
}

export const carDataToDataLayerItem = (
  carDataRaw: BaseCarData | ProductListDataRow | ProductDetailsPayload,
  index: number = 0,
): DataLayerProduct => {
  const carData = 'id' in carDataRaw ? productDetailPayloadToBaseCarData(carDataRaw) : carDataRaw;

  return {
    index,
    quantity: 1,
    currency: 'AUD',
    item_id: carData.sku,
    item_name: carData.model,
    price: carData.price,
    item_brand: carData.makeModel[0],
    item_category: carData.makeModel[1],
    item_category2: carData.bodyType,
    item_category3: carData.transmission,
    item_category4: carData.ancapRating,
    item_category5: `${carData.buildYear}`,
    item_variant: carData.colour,
  };
};

export const pushToDataLayerProductCard = (
  product: ProductListDataRow | BaseCarData,
  index: number,
  eventInfo: EventInfo,
) => {
  const event = {
    event: eventInfo.event,
    variant: eventInfo.variant,
    name: eventInfo.name,
    value: eventInfo.value,
    index,
    ecommerce: {
      items: [carDataToDataLayerItem(product)],
    },
  };
  pushToDataLayer(event);
};
